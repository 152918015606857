<template>
	<div class="wastenotificationsFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md12>
				<v-row dense>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.portcallnumber')" :value="getPortcallNumber"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.mships.nameofship')" :value="getShipName"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('wastenotifications.imo')" :value="getShipIMO"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('wastenotifications.flag')" :value="getFlag"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.portofcall.title')" :value="getPort"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('wastenotifications.headerlastdeliveryport')" :value="getlastdelport"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('wastenotifications.headernextdeliveryport')" :value="getnextdelport"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('mdhealt.status')" :value="getStatus"></pui-field>
					</v-col>
					<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
						<pui-field :label="this.$t('header.notificationitemid')" :value="getNotificationid"></pui-field>
					</v-col>
				</v-row>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'wastenotifications-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'wastenotifications'
		};
	},
	computed: {
		getPortcallNumber() {
			return this.model && this.model.visitid ? this.model.visitid : '';
		},
		getShipName() {
			return this.model && this.model.shipname ? this.model.shipname : '';
		},
		getShipIMO() {
			return this.model && this.model.imo ? this.model.imo : '';
		},
		getFlag() {
			return this.model && this.model.flagdesc ? this.model.flagdesc : '';
		},
		getPort() {
			return this.model && this.model.portofcall ? this.model.portofcall : '';
		},
		getlastdelport() {
			return this.model && this.model.lastporttra ? this.model.lastporttra : '';
		},
		getnextdelport() {
			return this.model && this.model.nextporttra ? this.model.nextporttra : '';
		},
		getStatus() {
			return this.model && this.model.status ? this.model.status : '';
		},
		getNotificationid() {
			return this.model && this.model.notificationid ? this.model.notificationid : '';
		}
	}
};
</script>
